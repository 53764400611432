// src/pages/HomePage.tsx
import React from 'react';
import { motion } from 'framer-motion';
import {
  FaInstagram,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaClock,
} from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ReactComponent as LogoIcon } from '../../assets/images/Logo.svg'; // Importing the local logo

// Logo Component
const Logo: React.FC = () => <LogoIcon className="w-32 h-auto md:w-40" />;

// Featured Dishes Carousel Component
const FeaturedCarousel: React.FC = () => (
  <Carousel
    showThumbs={false}
    autoPlay
    infiniteLoop
    interval={5000}
    showStatus={false}
    className="rounded-lg overflow-hidden"
  >
    <div>
      <img
        src="https://santaplaya-bucket.s3.us-west-2.amazonaws.com/three_dishes_in_a_square.webp"
        alt="Three Dishes"
        className="object-cover h-auto w-full"
        loading="lazy"
      />
      {/* Removed the overlaid text */}
    </div>
    <div>
      <img
        src="https://santaplaya-bucket.s3.us-west-2.amazonaws.com/fish_taco.webp"
        alt="Fish Taco"
        className="object-cover h-auto w-full"
        loading="lazy"
      />
      {/* Removed the overlaid text */}
    </div>
    <div>
      <img
        src="https://santaplaya-bucket.s3.us-west-2.amazonaws.com/fish_taco2.webp"
        alt="Fish Taco 2"
        className="object-cover h-auto w-full"
        loading="lazy"
      />
      {/* Removed the overlaid text */}
    </div>
    <div>
      <img
        src="https://santaplaya-bucket.s3.us-west-2.amazonaws.com/fresh_seafood_cocktail.webp"
        alt="Fresh Seafood Cocktail"
        className="object-cover h-auto w-full"
        loading="lazy"
      />
      {/* Removed the overlaid text */}
    </div>
    {/* Add more slides as needed without overlaid text */}
  </Carousel>
);

// Business Information Component
const BusinessInfo: React.FC = () => (
  <div className="bg-white shadow-md rounded-lg p-6">
    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
      {/* Location */}
      <div className="flex items-center mb-4 md:mb-0">
        <FaMapMarkerAlt className="text-primary text-2xl mr-2" />
        <div>
          <h2 className="text-2xl font-semibold text-secondary">Location</h2>
          <p>1230 State St. STE C</p>
          <p>Santa Barbara, CA 93101</p>
        </div>
      </div>
      {/* Hours */}
      <div className="flex items-center">
        <FaClock className="text-primary text-2xl mr-2" />
        <div>
          <h2 className="text-2xl font-semibold text-secondary">Hours</h2>
          <p>Open Daily: 11am - 9pm</p>
        </div>
      </div>
    </div>

    <div className="mt-6 flex flex-col md:flex-row md:items-center md:justify-between">
      {/* Contact */}
      <div className="flex items-center mb-4 md:mb-0">
        <FaPhoneAlt className="text-primary text-2xl mr-2" />
        <div>
          <h2 className="text-2xl font-semibold text-secondary">Contact</h2>
          <p>
            <a
              href="tel:8056795657"
              className="text-secondary hover:text-primary transition-colors"
            >
              (805) 679-5657
            </a>
          </p>
        </div>
      </div>
      {/* Social Media */}
      <div className="flex items-center">
        <FaInstagram className="text-primary text-2xl mr-2" />
        <div>
          <h2 className="text-2xl font-semibold text-secondary">Follow Us</h2>
          <p>
            <a
              href="https://instagram.com/santa_playa_mariscos"
              target="_blank"
              rel="noopener noreferrer"
              className="text-secondary hover:text-primary transition-colors flex items-center"
              aria-label="Follow us on Instagram"
            >
              @santa_playa_mariscos <FaInstagram className="ml-1" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
);

// Menus Section Component
const MenusSection: React.FC = () => (
  <div className="bg-white shadow-md rounded-lg w-full max-w-md mx-auto overflow-hidden">
    <motion.a
      href="https://santaplaya-bucket.s3.us-west-2.amazonaws.com/Hand+Menu.pdf"
      target="_blank"
      rel="noopener noreferrer"
      className="block cursor-pointer"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      aria-label="View Our Menu (PDF)"
    >
      {/* Header */}
      <div className="bg-primary text-white text-center py-4">
        <h2 className="text-3xl font-semibold">The Menu</h2>
      </div>

      {/* Image */}
      <div className="relative">
        <img
          src="https://santaplaya-bucket.s3.us-west-2.amazonaws.com/three_dishes.webp"
          alt="Our Menu"
          className="w-full h-auto"
          loading="lazy"
        />
      </div>

      {/* Footer */}
      <div className="bg-secondary text-white text-center py-2">
        <p className="text-lg font-medium">View Menu (PDF)</p>
      </div>
    </motion.a>
  </div>
);

// Google Maps Component
const GoogleMaps: React.FC = () => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden">
    <h2 className="text-3xl font-semibold text-primary mb-4 text-center">
      Find Us Here
    </h2>
    <div className="w-full h-96">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.098073624163!2d-119.70795542341737!3d34.42426387301924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e915002cffbe2b%3A0x22db976408faf77b!2sSanta%20Playa%20Mariscos!5e0!3m2!1sen!2sus!4v1728187094035!5m2!1sen!2sus"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Santa Playa Mariscos Location"
      ></iframe>
    </div>
  </div>
);

// Footer Component
const Footer: React.FC = () => (
  <motion.footer
    className="w-full bg-primary text-white py-6"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 1.5, duration: 1 }}
  >
    <div className="max-w-4xl mx-auto flex flex-col md:flex-row items-center justify-between">
      <Logo />
      <p className="mt-4 md:mt-0">
        &copy; {new Date().getFullYear()} Santa Playa Mariscos. All rights
        reserved.
      </p>
      <div className="flex space-x-4 mt-4 md:mt-0">
        <a
          href="https://instagram.com/santa_playa_mariscos"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:text-secondary transition-colors"
          aria-label="Instagram"
        >
          <FaInstagram size={24} />
        </a>
        {/* Add more social icons if needed */}
      </div>
    </div>
  </motion.footer>
);

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      {/* Hero Section */}
      <motion.header
        className="w-full h-screen bg-cover bg-center flex items-center justify-center"
        style={{
          backgroundImage:
            "url('https://santaplaya-bucket.s3.us-west-2.amazonaws.com/front.webp')",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <div className="bg-black bg-opacity-50 p-8 rounded flex flex-col items-center">
          <Logo />
          <motion.h1
            className="text-4xl md:text-6xl font-extrabold text-white mb-4 mt-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Santa Playa Mariscos
          </motion.h1>
          <motion.p
            className="text-lg md:text-2xl text-white"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            Fresh Seafood in Santa Barbara
          </motion.p>
        </div>
      </motion.header>

      {/* Business Information */}
      <motion.main
        className="w-full max-w-4xl px-4 py-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <BusinessInfo />
      </motion.main>

      {/* Featured Dishes Carousel */}
      <motion.section
        className="w-full max-w-4xl px-4 py-12"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <FeaturedCarousel />
      </motion.section>

      {/* Menus Section */}
      <motion.section
        className="w-full max-w-4xl px-4 py-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <MenusSection />
      </motion.section>

      {/* Google Maps Section */}
      <motion.section
        className="w-full max-w-4xl px-4 py-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1 }}
      >
        <GoogleMaps />
      </motion.section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
